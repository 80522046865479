import React, { useState , useEffect} from "react";
 import {
  Link ,useNavigate
} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';


import {
 EmailShareButton,
 FacebookShareButton,
  
 LinkedinShareButton,
  
 
  
 WhatsappShareButton
  
} from "react-share";
 

 
import { UrlSplitter,SetNotificationAfterName,GetThumbnailImage,postDetailurl,userProfileurl,votePosturl,frontUrl,Url,SiteLogo,SiteLogoDark,AkuserId,Akusername, Notifyalert, RemoveNotificationAfterName } from '../Frontapi.js';

 import {addReelIcon,userIcon ,challengeIcon,angleLeft ,challengewinnerIcon} from '../ImagesIcons.js';
 import Sidebar from "../includes/Sidebar";

   import Singlepost from "../pages/Singlepost"


 
import { DynamicImagesUrl } from '../GetDynamicImages.js';
 

 


 

 const Results = ( ) => {

const challengeid = UrlSplitter(2);
const username = UrlSplitter(3);


  const navigate = useNavigate();


 
const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)
/**Main page*/

 


  const [challengetitle, setchallengetitle] = useState('');
  const [ challengeresults, setchallengeresults] = useState([]);




 
 
 


 
const GetChallenge=async()=>{


 
  
 
   var postdata={  id : challengeid  , username : username   };

    const response = await fetch(Url+"getcontestsresults", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
    

        if(data.data.redirect == 1){
             navigate("/challenge")
        }

 
    setchallengetitle(data.data.title );
      setchallengeresults(data.data.results );

      
     } else{
         
     }

      
    })


 




}
 

 
 

 useEffect(() => {
 GetChallenge()
 },[]);
 


return (<div>
  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
   <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
     <Sidebar showBottomNavbar={BottomNavbarstatus} />
   </div>
   <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
     <div className="page-content">
      <div className="container-mobile">
         <div>



         
        
    <div className="page-container-section" >
           <h1 className="page-container-title cur-poi"  > 
            <span className="goto-section  " > <Link  to={-1}    >  <img src={angleLeft} /> </Link>  </span> {challengetitle}


 


           </h1>
         </div>

  




 <div>

          <div className="challenge-wrapper">
           {challengeresults.map((challengeresult) => (

    <div className=" col-12 col-md-12 col-sm-12 col-lg-12  " >
      <div className=" col-12 col-md-12 col-sm-12 col-lg-12 row">
         <div className=" col-12 col-md-12 col-sm-12 col-lg-12 text-left text-white"> 
              <h4 className="text-white mb-10">  {challengeresult.question} </h4>

                <p>  Answer :  {challengeresult.answer}</p>

                <p className={'contest-result-'+challengeresult.result}  > User Answer : {(challengeresult.useranswer == '' ) ?  'Skipped / Not Answered'  : challengeresult.useranswer }</p>

                <hr/>
         </div>
         
      </div>
   </div>
 


           ))}

         



         </div>
         
        </div>
  





       

          </div>  
       
         
       
      </div>
     </div>
   </div>
  </div>
</div>

  )













 }
export default Results;