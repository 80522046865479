import React, { useState ,useEffect} from "react";
   import {
    Link 
} from "react-router-dom";
 import io from 'socket.io-client';

 import Header from '../includes/Header';


 import Sidebar from "../includes/Sidebar";


   import { filterIcon} from '../ImagesIcons.js';


import {frontUrl,SiteLogoDark,SiteLogo ,Url,userProfileurl ,postDetailurl,AkuserId , Akusername } from '../Frontapi.js';


   import { angleLeft} from '../ImagesIcons.js';

 
 import { socketUrl} from '../../socketcall/Socketcall.js';


 
const Messages = () => {
   const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

 const [messageuserlist,setmessageuserlist]=useState([])

 const [messagefiltermenu,setmessagefiltermenu]=useState(0)
const socket = io.connect(socketUrl);


 const [allactive,setAllactive]=useState('active')
 const [unreadactive,setUnreadactive]=useState('')




const SearchMessageUsers=  async (e) => {


        var term = e.target.value;
        var show_boxes= document.getElementsByClassName('message-list-box')



        Array.prototype.forEach.call(show_boxes, function(el) {

        el.classList.add("display_none")


        var userbox = el.attributes.getNamedItem('data-attr-name').value;

        if (userbox.indexOf(term) != -1  ) {

            el.classList.remove("display_none")
        }



        });


    


}



  const Getmessagecontacts =  async (value='') => {
  var postdata={ 'userid':AkuserId , 'unread':value };


setAllactive('')
setUnreadactive('')
if(value == '1'){
setUnreadactive('active')

}else{
    setAllactive('active')

}


        const response = await fetch(Url+"get-messages-users", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            var messageuserlist=data.data;

           



          setmessageuserlist(messageuserlist)


              } else{
                 
          }

           
       })


}


const showMessagefiltermenu = async()=>{

    if(messagefiltermenu == 1){
setmessagefiltermenu(0)
    }else{
        setmessagefiltermenu(1)
    }




}

 
useEffect(() => {
    
    Getmessagecontacts();


  socket.emit("joinuser",{token:Akusername});


    socket.on("newmessagereceived", (data) => {
Getmessagecontacts();

         
         
        
    })  




    }, [messagefiltermenu]);


  return (

      <div>
       
   <Header pageInfo="Messages" />

     
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
          <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 "> 

               
<Sidebar showBottomNavbar={BottomNavbarstatus} />
           </div>
            <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 full-section"> 


              <div className="page-content">

                <div className="profile-container"> 

                 <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > <span className="goto-section mob-view-back display_none" ><Link  to={-1}>  <img src={angleLeft} /> </Link>  </span> Messages</h1>
                </div>
                
                 <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row   col-12 ">
                 <div className="col-md-10 col-lg-10 col-sm-10 col-xs-10 col-10   pos-relative  ">
                 <i className="fa  fa-search mes-search-input-icon"> </i> 
<input type="text" className="search-input message-search-input" placeholder="Search user"  onKeyUp={(e)=>SearchMessageUsers(e)} />
                 </div>
                 <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2  col-2    ">

     <div className="filter-message-section"> 

      <a className="f-dropdown-link prof-nav-icon"  >  <img className="filter-icon"    src={filterIcon} /> 

    <ul className="f-prof-dropdown">

     <li className={ allactive+ " py-2 px-4"} onClick={ (unread)=> Getmessagecontacts('') } >  All  < /li>
          <li className={ unreadactive+ " py-2 px-4"} onClick={ (unread)=> Getmessagecontacts(1) } >  Unread  < /li>


    </ul>

      </a>


           {/*<img className="filter-icon"  onClick={showMessagefiltermenu} src={filterIcon} /> 

            <div className={ (messagefiltermenu == 1) ? 'message-filter-menu' :'display_none message-filter-menu' }   >
           <h6 className={ allactive+ " py-2 px-4"} onClick={ (unread)=> Getmessagecontacts('') }>All</h6>
         <h6 className={unreadactive+ " py-2 px-4"}  onClick={ (unread)=> Getmessagecontacts(1) }  >Unread</h6> 
          
      </div>  */ }
       </div>
     


                 </div>   

                  </div>
   

    
     { (messageuserlist.length>0) ? 
             

<div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row message-wrapper  ">
                                   {messageuserlist.map((messageusersingle) => (
<div className={messageusersingle.unread == 1  ? "message-list-box unread-message" : "message-list-box" }  data-attr-name={messageusersingle.username} >
   
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 message-single padding-0 row   ">
         <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 col-2">
            <div className="message-single-section-img">

            <Link className="message-single-link" to={frontUrl+'m/'+messageusersingle.username}  >
            <img src={messageusersingle.profilepicture} />
            </Link>

            </div>
         </div>
         <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8 col-8">
            <div className="message-single-section-title">
               <p>
               <Link className="message-single-link" to={frontUrl+'m/'+messageusersingle.username}  >
                {messageusersingle.username} 
               </Link>
               </p>
            </div>
         </div>
      </div>
   
   
</div>


  ))}
</div>


: <div className="no-records">  No messages found </div>
} 






 
      
  

               </div>
               </div>



           </div>
              

     </div>
    
           

      </div>
 
 
  );
};
export default Messages;