import React, { useState ,useEffect} from "react";
   import {
    Link 
} from "react-router-dom";
import reactStringReplace from 'react-string-replace';
 import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

 import Header from "../includes/Header";

 import Sidebar from "../includes/Sidebar";
 import Footer from "../includes/Footer";



import {SetNotificationAfterName,Notifyalert,UrlSplitter,ReplaceRender,SiteLogo ,Url,userProfileurl ,postDetailurl,AkuserId,frontUrl,SiteLogoDark,Socketiourl,Akusername} from '../Frontapi.js';



 


 
const Notifications = () => {
    const[updateSidecount,setupdateSidecount]=useState(0)
   const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)
 
const [title,setTitle]= useState('')
const [content,setContent]= useState('')

const [contactname,setcontactName]=useState('');

const [contactemail,setcontactEmail]=useState('');
const [message,setMessage]=useState('');


const [offlemail,setOfflemail]=useState('');
const [offlphone,setOfflphone]=useState('');
const [offlmobile,setOfflmobile]=useState('');
const [offladdress,setOffladdress]=useState('');


   const cmsurl = UrlSplitter(1);





 const Getsettings = async (e) => {
  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-front-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setOfflemail(settingsdata.email );
      setOfflphone(settingsdata.phone );
      setOfflmobile(settingsdata.mobile );
      setOffladdress(settingsdata.address+ ', '+settingsdata.city );


            
         
          } else{
              //  ;
          }

           
       })



  } catch (error) {
      
  }

 } 





 const Getcms =async() =>{
 

 var postdata={  cmsurl:cmsurl}

         const response = await fetch(Url+"get-cms-data", {
        method: "POST",
         headers: {
          "Content-Type": "application/json" 
        } ,
        body:JSON.stringify({postdata}) 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
          
    } else{
               
          }

           
       })
 
  

}




  const SubmitContactus = async (e) => {
    e.preventDefault();
 
     try {



    

     var postdata={contactemail:contactemail, contactmessage:message , contactname : contactname };

       /***Check Required***/
            var required_fields= document.getElementsByClassName('MustContactEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                        el.classList.add('mb-25');
                        if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                        el.classList.remove('mb-25');
                        error=1;

                        

                        SetNotificationAfterName(el.name, inputname + ' required')
             }
             });
             /***Check Required***/

            if(error === 1){
                    return false
            }


      const response = await fetch(Url+"create-inquiry", {
        method: "POST",
          headers: {
          "Content-Type": "application/json" 
        } ,
        body:JSON.stringify({postdata})  
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
                                         Notifyalert('success', data.message)

        document.getElementById("ContactForm").reset();
        setMessage('')
          } else{
              //  ;
          }

           
       })
 
    } catch (error) {
      console.log(error)
     }
  }; 


 
useEffect(() => {
    
   Getsettings();
 
    }, [title,content]);


  return (

   <div>
   <Header pageInfo="Contact Us" />
   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar showBottomNavbar={BottomNavbarstatus} updateSidecount={updateSidecount} noauthneeded="1" />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 full-section">
         <div className="page-content">
            <div className="cms-container">
               <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > Contact Us</h1>
               </div>
               <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row  ">
                  <div className="col-md-6 col-lg-4 col-sm-4 col-xs-4  col-12  ">
                     <div className="contact-info-section pt-md-75">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row  mb-25" >
                           <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 col-2 text-white" >  
                              <span className="contact-info-icon"><i className="fa fa-envelope"> </i>  </span>
                           </div>
                           <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9  col-10 text-left text-white" >  
                              <a href="mailto:contactus@alfakids.com" className="text-white"> {offlemail} </a>
                           </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row mb-25" >
                           <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 col-2 text-white " >  
                              <span className="contact-info-icon"> <i className="fa fa-phone"> </i>  </span>
                           </div>
                           <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-10  text-left text-white " >
                              <p> {offlphone}  </p>
                              {offlmobile}
                           </div>
                        </div>
                         <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row mb-25" >
                           <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 col-2 text-white " >  
                              <span className="contact-info-icon"> <i className="fa fa-map-marker"> </i>  </span>
                           </div>
                           <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-10  text-left text-white " >
{offladdress}                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-6 col-lg-8 col-sm-8 col-xs-12 col-12    ">
                     <h2 className="page-container-title cur-poi"   > Get in touch with us</h2>
                     <form autoComplete="off" id="ContactForm" >
                        <input autoComplete="off"  type="text" name="name" className="form-control contact-input mb-25 MustContactEnterField"   placeholder="Name*"  
                           onChange={(e) => setcontactName(e.target.value)} required/>
                        <input autoComplete="off"  type="text" name="email" className="form-control contact-input mb-25 MustContactEnterField"   placeholder="Email*"  
                           onChange={(e) => setcontactEmail(e.target.value)}
                        required/>
                        {/*<textarea onChange={(e) => setMessage(e.target.value)} name="message" placeholder="Enter your message*" rows="10" className="textarea-profile-input MustContactEnterField mb-25"></textarea>*/}

                          <ReactQuill value={message}  onChange={setMessage} name="message" placeholder="Enter your message*" rows="10" className="textarea-contact-input MustContactEnterField mb-25"  />

                        <button type="submit" className=" me-1 mb-1 contact-us-main-btn"  onClick={SubmitContactus}  >Submit</button>
                     </form>
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </div>
   </div>
</div>
  );
};
export default Notifications;