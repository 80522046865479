import React, { useState , useEffect} from "react";
  import {
  Link 
} from "react-router-dom";

import {  frontUrl  } from '../Frontapi.js';

 const Topbar = ({active}) => {

 

 
return (


 
         <div className="challenge-info-tabs" >


           <ul>
            <li   > <Link className={active == 'current' ? 'active':''}    to={frontUrl+'challenge'}   > Current</Link> </li>
            <li > <a className="pipeline"> &nbsp; </a> </li>

             <li   > <Link className={active == 'my' ? 'active':''}   to={frontUrl+'mychallenge'} > My</Link> </li>
            <li > <a className="pipeline"> &nbsp; </a> </li>



              <li   > <Link className={active == 'upcoming' ? 'active':''}   to={frontUrl+'upcomingchallenge'} > Upcoming</Link> </li>
            <li > <a className="pipeline"> &nbsp; </a> </li>


             <li   > <Link className={active == 'winners' ? 'active':''}   to={frontUrl+'challengewinners'} > Winners</Link> </li>
 

 
          
           </ul>
         </div>



	)













 }
export default Topbar;