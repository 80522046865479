import React from "react";
import {  Routes, Route } from "react-router-dom";

 

import FrontendLogin from "./frontend/auth/Login";
import FrontendRegister from "./frontend/auth/Register";
import FrontendRegisterTeacher from "./frontend/auth/Registerteacher";
import FrontendRegisterStudent from "./frontend/auth/Registerstudent";
import FrontendForgotpassword from "./frontend/auth/Forgotpassword";
import FrontendResetpassword from "./frontend/auth/Resetpassword";
import FrontendLogout from "./frontend/auth/Logout";
import FrontendTermsagreement from "./frontend/auth/Termsagreement";
import FrontendTermsagreementstudent from "./frontend/auth/Termsagreementstudent";



import FrontendHome from "./frontend/pages/Home";
import FrontendDashboard from "./frontend/pages/Dashboard";
import FrontendSearch from "./frontend/pages/Search";
import FrontendNewPost from "./frontend/pages/Newpost";
import FrontendEditPost from "./frontend/pages/Editpost";


import FrontendUserProfile from "./frontend/pages/Userprofile";
import PostDetailPage from "./frontend/pages/Postdetail";



import LivestreamsPage from "./frontend/streams/Livestreams";
import AddLivestream from "./frontend/streams/Addlivestream";
import EditLivestream from "./frontend/streams/Editlivestream";
import Mystream from "./frontend/streams/Mystreams";



import LivestreamsessionsPage from "./frontend/streamsessions/Livestreams";





import FrontendProfile from "./frontend/profile/Profile";



import Scrollpagination from "./frontend/Scrollpagination";


import FrontendNotifications from "./frontend/pages/Notifications";

import FrontendMessages from "./frontend/pages/Messages";

import FrontendUserMessage from "./frontend/pages/Messageuser";

import Socket from "./frontend/pages/Socket";


//import FrontendMyadverts from "./frontend/pages/Notifications";

 import FrontendInviteFriends from "./frontend/pages/Invitefriends";

import FrontendAddadvertisements from "./frontend/Ads/Newad";
 import FrontendOrderreturn from "./frontend/Ads/Orderreturn";

  import FrontendOrders from "./frontend/Ads/Orders";


  //import FrontendChallenges from "./frontend/Challenges/Challenges";

   // import FrontendVotepost from "./frontend/Challenges/Votepost";



//Challenge
import FrontendChallenge from "./frontend/Challenge/Listcurrent";

import FrontendUpcomingChallenge from "./frontend/Challenge/Listupcoming";
import FrontendChallengewinners from "./frontend/Challenge/Listwinners";

import FrontendMyChallenge from "./frontend/Challenge/Listmychallenge";


import FrontendAddChallenge from "./frontend/Challenge/Addchallenge";
import FrontendEditChallenge from "./frontend/Challenge/Editchallenge";
import FrontendChallengeAddQuestions from "./frontend/Challenge/Addchallenge";
 

import FrontendChallengeAttendeesResults from "./frontend/Challenge/Attendeesresults";


  import FrontendChallengeDetails from "./frontend/Challenge/Details";

 

//Contest
  import FrontendContest from "./frontend/Contest/Listcurrent";
  import FrontendContestDetails from "./frontend/Contest/Details";
  import FrontendContestAttend from "./frontend/Contest/Attend";
  import FrontendContestResults from "./frontend/Contest/Results";


  import FrontendAttendedContest from "./frontend/Contest/Listattended";


  import FrontendUpcomingContest from "./frontend/Contest/Listupcoming";
  import FrontendContestwinners from "./frontend/Contest/Listwinners";



 //Admin


import AdminLogin from "./admin/auth/Login";
import AdminLogout from "./admin/auth/Logout";
import  AdminForgotpassword from "./admin/auth/Forgotpassword";
import  AdminResetpassword from "./admin/auth/Resetpassword";



import AdminSettings from "./admin/profile/Settings";
import AdminChangepassword from "./admin/profile/Changepassword";
import AdminProfile from "./admin/profile/Profile";


import AdminDashboard from "./admin/pages/dashboard";


import AdminUserlist from "./admin/pages/users/List";
import AdminUseradd from "./admin/pages/users/Add";

import AdminRolelist from "./admin/pages/roles/List";
import AdminRoleadd from "./admin/pages/roles/Add";

import AdminCmslist from "./admin/pages/cms/List";
import AdminCmsadd from "./admin/pages/cms/Add";


import AdminEmailtemplateslist from "./admin/pages/emailtemplates/List";
import AdminEmailtemplatesadd from "./admin/pages/emailtemplates/Add";

import AdminSchoollist from "./admin/pages/schools/List";
import AdminSchooladd from "./admin/pages/schools/Add";


import AdminSubjectlist from "./admin/pages/subjects/List";
import AdminSubjectadd from "./admin/pages/subjects/Add";

import AdminClientlist from "./admin/pages/clients/List";
import AdminClientliststudents from "./admin/pages/clients/Liststudents";


import AdminPostslist from "./admin/pages/posts/List";
import AdminPostcommentslist from "./admin/pages/posts/Listcomments";

import AdminEnquirieslist from "./admin/pages/enquiries/List";

import AdminStreamslist from "./admin/pages/streams/List";
import AdminStreamreviewslist from "./admin/pages/streams/Listreviews";



import AdminAdslist from "./admin/pages/ads/List";
import AdminOrderslist from "./admin/pages/orders/List";


import FrontendAbout from "./frontend/cms/About"; 

import FrontendTermsConditions from "./frontend/cms/Terms"; 

import FrontendRefundPolicy from "./frontend/cms/Refund"; 

import FrontendPrivacyPolicy from "./frontend/cms/Privacy"; 


import FrontendContactUs from "./frontend/cms/Contact"; 

import AdminChallengeslist from "./admin/pages/challenges/List";
import AdminChallengesadd from "./admin/pages/challenges/Add";
import AdminChallengesubmissions from "./admin/pages/challenges/Listattendees";
import AdminChallengeusersubmissions from "./admin/pages/challenges/Listattendeesresults";


import AdminChallengespendingsubmissions from "./admin/pages/challenges/Listpendingposts";


import AdminChallengesquestions from "./admin/pages/challenges/Listquestions";


function App() { 
  return (

     <div className="  h-screen duration-500">
      
     
        <Routes>

          <Route path="/contact-us" element={<FrontendContactUs />} />

         <Route path="/about-us" element={<FrontendAbout />} />
          <Route path="/terms-conditions" element={<FrontendTermsConditions />} />
           <Route path="/privacy-policy" element={<FrontendPrivacyPolicy />} />
            <Route path="/refund-policy" element={<FrontendRefundPolicy />} />


          <Route path="/login" element={<FrontendLogin />} />
           <Route path="/teacher-register" element={<FrontendRegisterTeacher />} />
          <Route path="/student-register" element={<FrontendRegisterStudent />} />
          <Route path="/forgot-password" element={<FrontendForgotpassword />} />
          <Route path="/reset-password/:token" element={<FrontendResetpassword />} />
          <Route path="/signout" element={<FrontendLogout />} />
          <Route path="/terms-agreement" element={<FrontendTermsagreement />} />
          <Route path="/terms-agreement-student" element={<FrontendTermsagreementstudent />} />


          <Route exact path="/" element={<FrontendHome />} />
          <Route path="/dashboard" element={<FrontendDashboard />} />
          <Route path="/search" element={<FrontendSearch />} />


          <Route path="/my-profile/preferences" element={<FrontendProfile />} />
            <Route path="/my-profile" element={<FrontendProfile />} />


           <Route path="/create-new-post" element={<FrontendNewPost />} />
             <Route path="/edit-post/:id" element={<FrontendEditPost />} />


           
          <Route path="/scroll" element={<Scrollpagination />} />

          <Route path="/u/:id" element={<FrontendUserProfile />} />

          <Route path="/p/:id" element={<PostDetailPage />} />





    <Route path="/streamsessions" element={<LivestreamsessionsPage />} />

                    <Route path="/livestreams" element={<LivestreamsPage />} />
                    <Route path="/add-stream" element={<AddLivestream />} />
                    <Route path="/my-streams" element={<Mystream />} />
                     <Route path="/edit-stream/:id" element={<EditLivestream />} />


          <Route path="/notifications" element={<FrontendNotifications />} />             
          <Route path="/messages" element={<FrontendMessages />} />   

           <Route path="/m/:id" element={<FrontendUserMessage />} />          

           <Route path="/socket" element={<Socket />} />    
           <Route path="/invite-friends" element={<FrontendInviteFriends />} />    

            <Route path="/newad" element={<FrontendAddadvertisements />} />    


 
             <Route path="/orders" element={<FrontendOrders />} />    



             <Route path="/order-return/:id" element={<FrontendOrderreturn />} />


              

             {/*}  <Route path="/v-post/:pid/:cid" element={<FrontendVotepost />} />*/ }



               <Route path="contest/details/:id" element={<FrontendContestDetails />} />
               <Route path="contest/attend/:id" element={<FrontendContestAttend />} />
               <Route path="contest/results/:id" element={<FrontendContestResults />} />

               
               
               <Route path="/contest" element={<FrontendContest />} />
               <Route path="/attendedcontest" element={<FrontendAttendedContest />} />
               <Route path="/upcomingcontest" element={<FrontendUpcomingContest />} />
               <Route path="/contestwinners" element={<FrontendContestwinners />} />



               <Route path="/challenge" element={<FrontendChallenge />} />
               <Route path="/upcomingchallenge" element={<FrontendUpcomingChallenge />} />
               <Route path="/challengewinners" element={<FrontendChallengewinners />} />

               <Route path="/mychallenge" element={<FrontendMyChallenge />} />
               <Route path="/addchallenge" element={<FrontendAddChallenge />} />
               <Route path="/editchallenge/:id" element={<FrontendEditChallenge />} />


               

               <Route path="/challengedetails/:id" element={<FrontendChallengeDetails />} />

               <Route path="/challengeattendeesresults/:id/:userid" element={<FrontendChallengeAttendeesResults />} />

               <Route path="/addchallengequestions/:id" element={<FrontendChallengeAddQuestions />} />


              <Route path="/*" element={<FrontendDashboard />} />

          {/* Admin */}


          <Route path="/admin/" element={<AdminLogin />} />
         <Route path="/admin/login" element={<AdminLogin />} />
         <Route path="/admin/logout" element={<AdminLogout />} />
     
           <Route path="/admin/forgot-password" element={<AdminForgotpassword />} />
           <Route path="/admin/reset-password/:token" element={<AdminResetpassword />} />


           <Route path="/admin/change-password" element={<AdminChangepassword />} />
           <Route path="/admin/settings" element={<AdminSettings />} />
           <Route path="/admin/my-profile" element={<AdminProfile />} />

           <Route path="/admin/dashboard" element={<AdminDashboard />} />

           <Route path="/admin/users" element={<AdminUserlist />} />
            <Route path="/admin/users/add" element={<AdminUseradd />} />
             <Route path="/admin/users/edit/:id" element={<AdminUseradd />} />
             <Route path="admin/roles" element={<AdminRolelist />} />
              <Route path="/admin/roles/add" element={<AdminRoleadd />} />
             <Route path="/admin/roles/edit/:id" element={<AdminRoleadd />} />

               <Route path="admin/cms" element={<AdminCmslist />} />
               <Route path="admin/cms/edit/:id" element={<AdminCmsadd />} />


               <Route path="admin/emailtemplates" element={<AdminEmailtemplateslist />} />
               <Route path="admin/emailtemplates/edit/:id" element={<AdminEmailtemplatesadd />} />


               <Route path="admin/schools" element={<AdminSchoollist />} />
              <Route path="/admin/schools/add" element={<AdminSchooladd />} />
             <Route path="/admin/schools/edit/:id" element={<AdminSchooladd />} />

                <Route path="admin/subjects" element={<AdminSubjectlist />} />
              <Route path="/admin/subjects/add" element={<AdminSubjectadd />} />
             <Route path="/admin/subjects/edit/:id" element={<AdminSubjectadd />} />


               <Route path="admin/challenges" element={<AdminChallengeslist />} />
              <Route path="/admin/challenges/add" element={<AdminChallengesadd />} />
             <Route path="/admin/challenges/edit/:id" element={<AdminChallengesadd />} />
              <Route path="/admin/challengesubmissions/:id" element={<AdminChallengesubmissions />} />

              <Route path="/admin/challengeuserresults/:id/:userid" element={<AdminChallengeusersubmissions />} />

              

                <Route path="admin/challengequestions/:id" element={<AdminChallengesquestions/>} />

            <Route path="/admin/challengependingposts/" element={<AdminChallengespendingsubmissions/>} />


             <Route path="admin/teachers" element={<AdminClientlist />} />
              <Route path="admin/students" element={<AdminClientliststudents />} />


              <Route path="admin/posts" element={<AdminPostslist />} />
              <Route path="admin/comments/:id" element={<AdminPostcommentslist />} />
              

              <Route path="admin/enquiries" element={<AdminEnquirieslist />} />


              <Route path="admin/streamreviews/:id" element={<AdminStreamreviewslist />} />      
              <Route path="admin/streams" element={<AdminStreamslist />} />


              <Route path="admin/ads" element={<AdminAdslist />} />
              <Route path="admin/orders" element={<AdminOrderslist />} />
              
           
        </Routes>
       
    </div>
    );
}

export default App;
