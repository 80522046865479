import React from "react";
 import {DynamicImagesUrl} from './GetDynamicImages.js';



//import homeIcon from '../assets/images/frontend/icons/home.svg';
// import challengeIcon from '../assets/images/frontend/icons/challenge.svg';
// import searchIcon from '../assets/images/frontend/icons/search.svg';
// import liveStreamIcon from '../assets/images/frontend/icons/live-stream.svg';
// import preferenceIcon from '../assets/images/frontend/icons/preference.svg';


// import HeadBg from '../assets/images/frontend/home-head-bg.png';
// import MidBg from '../assets/images/frontend/home-mid-bg.png';
// import FootBg from '../assets/images/frontend/home-footer-bg.png';

// import NewMidBg from '../assets/images/frontend/home-new-mid-bg.png';

 


const GetImage=async(imagepath)=>{

 return DynamicImagesUrl + imagepath;

}


const homeIcon=await GetImage('icons/home.svg')
const challengeIcon=await GetImage('icons/challenge.svg')
const challengewinnerIcon=await GetImage('icons/challenge-winner.png')

const searchIcon=await GetImage('icons/search.svg')
const liveStreamIcon=await GetImage('icons/live-stream.svg')
const preferenceIcon=await GetImage('icons/preference.svg')


const streamingIcon=await GetImage('icons/streaming.png')
const userIcon=await GetImage('icons/user.svg')
const signOutIcon=await GetImage('icons/sign-out.png')
const messageIcon=await GetImage('icons/message.png')
const referralIcon=await GetImage('icons/invite-friends.png')
const angleLeft=await GetImage('icons/angle-left.png')
const calendarIcon=await GetImage('icons/calendar.svg')
const clockIcon=await GetImage('icons/clock.svg')
//const addReelIcon=await GetImage('icons/add-reel.svg')
const addReelIcon=await GetImage('icons/add-reel.png')

const filterIcon=await GetImage('icons/filter.png')
const logOutIcon=await GetImage('icons/log-out.png')


 
const HeadBg=await GetImage('home-head-bg.png')
const MidBg=await GetImage('home-mid-bg.png')
const FootBg=await GetImage('home-footer-bg.png')
const NewMidBg=await GetImage('home-new-mid-bg.png')


const SiteLogo=await GetImage('logo.svg')
const SiteMLogo=await GetImage('m-logo.svg')


const SiteTextLogo=await GetImage('text-logo.svg')

const SiteLogoDark=await GetImage('logo-dark.png')
const SiteFavicon=await GetImage('favicon.png')


const ChallengeQuestionImg=await GetImage('contest/questionbg.png')

 const ChallengeInstituteImg=await GetImage('contest/institutelogo.png')


export {ChallengeInstituteImg,ChallengeQuestionImg,logOutIcon,SiteLogo,SiteMLogo,SiteTextLogo,SiteLogoDark ,SiteFavicon, filterIcon,addReelIcon,HeadBg,MidBg,FootBg,NewMidBg,homeIcon,challengeIcon,challengewinnerIcon,searchIcon,liveStreamIcon,preferenceIcon,streamingIcon,userIcon,signOutIcon,messageIcon,referralIcon ,angleLeft ,calendarIcon,clockIcon}