import React, { useState , useEffect} from "react";
 import {
  Link 
} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';


import {
 EmailShareButton,
 FacebookShareButton,
  
 LinkedinShareButton,
  
 
  
 WhatsappShareButton
  
} from "react-share";
 

 
import { UrlSplitter,SetNotificationAfterName,GetThumbnailImage,postDetailurl,userProfileurl,votePosturl,frontUrl,Url,SiteLogo,SiteLogoDark,AkuserId,Akusername, Notifyalert, RemoveNotificationAfterName } from '../Frontapi.js';

 import {addReelIcon,userIcon ,challengeIcon,angleLeft ,challengewinnerIcon} from '../ImagesIcons.js';
 import Sidebar from "../includes/Sidebar";

   import Singlepost from "../pages/Singlepost"


 
import { DynamicImagesUrl } from '../GetDynamicImages.js';
 

 import ContestTopbar from "./Topbar";



 

 const Details = ( ) => {
const challengeid = UrlSplitter(2);


const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)
/**Main page*/


const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage,setrecordsPerPage] = useState(10);
const [checkrecordsLength,setcheckrecordsLength] = useState('Loading...')



 

const [challengetitle, setchallengetitle] = useState('');
const [challengestartdatetime, setchallengestartdatetime] = useState('');
const [challengeenddatetime, setchallengeenddatetime] = useState('');
const [challengestandard, setchallengestandard] = useState('');
const [challengesubject, setchallengesubject] = useState('');
const [challengestatus, setchallengestatus] = useState('');

  const [challengeprize, setchallengeprize] = useState('');


  const [challengesattendeesList, setchallengesattendeesList] = useState([]);

 

const GetChallengeDetails=async()=>{


 
  
 
   var postdata={   'currentpage':currentPage,'perpage':recordsPerPage,   'checkuserid' :AkuserId, 'challengeid': challengeid };

    const response = await fetch(Url+"getcontestdetailsattendees", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
 
 var data= data.data;

var challengedata = data.challengedata

setchallengetitle(challengedata.title)
setchallengestatus(challengedata.status)
setchallengesubject(challengedata.subjectname)
setchallengeprize(challengedata.prize)

setchallengestandard(challengedata.standard)
setchallengestartdatetime(challengedata.startdatetime)
setchallengeenddatetime(challengedata.enddatetime)


if(data.attendeesdata.length == 0){
   setcheckrecordsLength(" ")
}else{
     setchallengesattendeesList(data.attendeesdata );

}

 
    
     
     } else{
         
     }

      
    })


 




}
 
 

 
 

 useEffect(() => {
 GetChallengeDetails()
 },[]);
 


return (<div>
  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
   <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
     <Sidebar showBottomNavbar={BottomNavbarstatus} />
   </div>
   <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
     <div className="page-content">
      <div className="container-mobile">
         <div>
         <div className="page-container-section" >
           <h1 className="page-container-title cur-poi"  > 
            <span className="goto-section   " > <Link  to={-1}>  <img src={angleLeft} /> </Link>  </span>Challenge Details


 


           </h1>
         </div>

  
    <div>


         <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 challenge-info-data row">
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p className="challenge-info-label text-left text-white">Title : </p>
           </div>
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p  className="challenge-info-value text-left text-white">{challengetitle}</p>
           </div>
        </div>

        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 challenge-info-data row">
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p className="challenge-info-label text-left text-white">Prize : </p>
           </div>
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p  className="challenge-info-value text-left text-white">{challengeprize}</p>
           </div>
        </div>


        


         <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 challenge-info-data row">
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p className="challenge-info-label text-left text-white">Starts : </p>
           </div>
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p  className="challenge-info-value text-left text-white">{challengestartdatetime}</p>
           </div>
        </div>


        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 challenge-info-data row">
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p className="challenge-info-label text-left text-white">Ends : </p>
           </div>
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p  className="challenge-info-value text-left text-white">{challengeenddatetime}</p>
           </div>
        </div>



         <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 challenge-info-data row">
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p className="challenge-info-label text-left text-white">Subject : </p>
           </div>
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p  className="challenge-info-value text-left text-white">{challengesubject}</p>
           </div>
        </div>


        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 challenge-info-data row">
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p className="challenge-info-label text-left text-white">Standard : </p>
           </div>
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p  className="challenge-info-value text-left text-white">{challengestandard}</p>
           </div>
        </div>



        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 challenge-info-data row">
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p className="challenge-info-label text-left text-white">Status : </p>
           </div>
           <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
              <p  className="challenge-info-value text-left text-white">{challengestatus}</p>
           </div>
        </div>




    </div>


 <div>

         {(challengesattendeesList.length) > 0 ?
 <div>

         <h3 className="text-left text-white">Attendees</h3>
         <div className="challenge-wrapper">
           {challengesattendeesList.map((challenge) => (

          
     <div className="challenge-single cur-poi">
     <Link to={frontUrl+'challengeattendeesresults/'+challengeid+'/'+challenge.username} >

   <div className=" col-12 col-md-12 col-sm-12 col-lg-12 challenge-single-title" >
      <div className=" col-12 col-md-12 col-sm-12 col-lg-12 row">
         <div className=" col-12 col-md-3 col-sm-3 col-lg-3 "> 
            <img  className="img-responsive" src={challenge.profilepicture} />
         </div>
         <div className=" col-12 col-md-9 col-sm-9 col-lg-9 ">
            <span>{challenge.username}</span>   
            <span className=" col-12 col-md-12 col-sm-12 col-lg-12 display_flex">  
              {challenge.questionsattended}
            
            </span>
          </div>
      </div>
   </div>
   </Link>

  <p> <Link  className=  "me-1 mb-1 contest-sec-btn   bg-yellow-amigo w-50"    to={frontUrl+'challengeattendeesresults/'+challengeid+'/'+challenge.username} >View Full Results</Link></p>



</div>

 




           ))}

         



         </div>
          </div>

         : 
         <div className="no-records"> {checkrecordsLength} </div>
         }
        </div>
       

          </div>  
       
         
       
      </div>
     </div>
   </div>
  </div>
</div>

  )













 }
export default Details;