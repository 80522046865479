import React, {
    useState,
    useEffect
} from "react";
import {
    Link
} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Timepicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import {
    CTimePicker
} from '@coreui/react-pro'
import Select from 'react-select';


import {
    ChallengeInstituteImg,
    addReelIcon,
    userIcon,
    challengeIcon,
    angleLeft,
    challengewinnerIcon,
    calendarIcon,
    clockIcon
} from '../ImagesIcons.js';
import {
    frontUrl,
    challengeFees,
    Url,
    AkuserId,
    Notifyalert,
    GetSubjectsCommon
} from '../Frontapi.js';
import Sidebar from "../includes/Sidebar";
const Addchallenge = ({
        active
    }) => {
        const [BottomNavbarstatus, setBottomNavbarstatus] = useState(1)


        const [showAgreementSection, setshowAgreementSection] = useState(1);
        const [showSetupContestSection, setshowSetupContestSection] = useState(0);
        const [showSetupContestQuestionSection, setshowSetupContestQuestionSection] = useState(0);


        const [showsubmitButton, setshowsubmitButton] = useState(1);
        const [challengesCount, setchallengesCount] = useState([]);
        //datepicker - timepicker
        var todaydate = new Date();
        todaydate.setDate(todaydate.getDate() + 1);


        const [currentDate, setCurrentDate] = useState(todaydate);
        const [showdatePickeroption, setshowdatePickeroption] = useState(0);
        const [date, setDate] = useState('');
        const [time, setTime] = useState('');
        const [displaydate, setdisplaydate] = useState('');
        const [displaytime, setdisplaytime] = useState('');



        const [bulkImport, setbulkImport] = useState(0);
        


        const ShowDatepickerSection = (value) => {
            setshowdatePickeroption(value)
        }
        const setDatevalue = (value) => {
            const d = new Date(value).toLocaleDateString('en-US');
            setDate(d)
            var displaydate = new Date(value).toLocaleDateString('en-GB');
            setdisplaydate(displaydate)
            setshowdatePickeroption(0)
        }
        const setTimevalue = (value) => {
            var timevalue = value.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [value];
            if (timevalue.length > 1) { // If time format correct
                timevalue = timevalue.slice(1); // Remove full string match value
                timevalue[5] = +timevalue[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
                timevalue[0] = +timevalue[0] % 12 || 12; // Adjust hours
            }
            setdisplaytime(timevalue.join(''))
            setTime(value)

            console.log(date)
            console.log(time)

        }
        //datepicker - timepicker
        const showAgreement = async () => {
            setshowAgreementSection(1)
            setshowSetupContestQuestionSection(0)
            setshowSetupContestSection(0)
        }
        const showSetupcontest = async () => {





            setshowAgreementSection(0)
            setshowSetupContestQuestionSection(0)
            setshowSetupContestSection(1)
        }


        const [timeerrormsg,setTimeerrormsg] =useState('')
        const showSetupcontestQuestion = async () => {

             setTimeerrormsg('')

             if(title == ''){

                document.getElementById("title").focus()
                return false
            }
             if(institutename == ''){

                document.getElementById("institutename").focus()
                return false
            }
             if(prize == ''){

                document.getElementById("prize").focus()
                return false
            }

            if(date == ''){

              setTimeerrormsg('Date and Time is required')
              return false
            }




            setshowAgreementSection(0)
            setshowSetupContestSection(0)
            setshowSetupContestQuestionSection(1)
        }

        const [questionerrormsg,setQuestionerrormsg] =useState('')
        const payContest = async () => {
            

            setQuestionerrormsg('')
            var required_fields= document.getElementsByClassName('MustEnterField')
              
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         el.classList.add('mb-10');
                        if(el.value === '' ){
                       
                        error=1;

                       

                        //SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });    

            if(error == 1){

                setQuestionerrormsg('Question and Options are required')
                 return false

            }
           
            setshowsubmitButton(0)


            const form = document.querySelector('form');
            const data = new FormData(form);
            var questionskey = []
            var questionidkey = []
            var option1key = []
            var option2key = []
            var option3key = []
            var option4key = []
            data.forEach(function(value, key) {
                if (key == 'question[]') {
                    var values = {
                        'value': value
                    }
                    questionskey.push(value)
                }
                if (key == 'questionid[]') {
                    var values = {
                        'value': value
                    }
                    questionidkey.push(value)
                }
                if (key == 'option1[]') {
                    var values = {
                        'value': value
                    }
                    option1key.push(value)
                }
                if (key == 'option2[]') {
                    var values = {
                        'value': value
                    }
                    option2key.push(value)
                }
                if (key == 'option3[]') {
                    var values = {
                        'value': value
                    }
                    option3key.push(value)
                }
                if (key == 'option4[]') {
                    var values = {
                        'value': value
                    }
                    option4key.push(value)
                }
            });
            var questions = {
                questions: questionskey,
                questionid: questionidkey,
                option1: option1key,
                option2: option2key,
                option3: option3key,
                option4: option4key
            };
            let formData = new FormData()
            formData.append('userid', AkuserId)
            formData.append('title', title)
            formData.append('prize', prize)
            formData.append('institutename', institutename)
            formData.append('institutelogo', institutelogo.data)
            formData.append('questions', JSON.stringify(questions))
            formData.append('date', date)
            formData.append('time', time)
            formData.append('subject', subject)
            formData.append('standard', standard)

             
            var postdata = {
                userid: AkuserId
            }
            const response = await fetch(Url + "pay-challenge", {
                    method: "POST",
                    body: formData
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        window.location.href = data.redirect
                    } else {
                        if (typeof data.message != 'undefined') {
                            Notifyalert('error', data.message)
                        }
                    }
                })
        }
        const GetChallengeCounts = async () => {
            var postdata = {
                'active': 1
            };
            const response = await fetch(Url + "getcontestcounts", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {

                     



                        setchallengesCount(data.data);
                    } else {}
                })
        }
        /*
         

        var questionsection = '<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12   row ">        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12    "> <input   type="text" name="question" class="new-challenge-input MustEnterField mb-10" placeholder="Enter Questions" /></div><div class="col-md-6 col-lg-6 col-sm-12 col-xs-12    ">                        <input   type="text" name="question" class="new-challenge-input MustEnterField mb-10" placeholder="Right Answer / Option1" /></div>                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12    ">                                        <input   type="text" name="question" class="new-challenge-input MustEnterField mb-10" placeholder="Option2" /></div>              <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12    ">                        <input   type="text" name="question" class="new-challenge-input MustEnterField mb-10" placeholder="Option3" /></div>                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12    ">                                        <input   type="text" name="question" class="new-challenge-input MustEnterField mb-10" placeholder="Option4" /></div>   </div>   <hr/>';

        */
        const AddQuestion = async () => {
            //createRoot(document.getElementById("questions_extra")).render(<p>Hellos</p>)
            //document.getElementById("questions_extra").append(<Questionsection />);
            var d1 = document.getElementById('questions_extra');
            //d1.insertAdjacentHTML('beforeend',  questionsection);
            // ReactDOM.render(<Questionsection />, document.getElementById('root'));
        }

        const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
   reader.onerror = reject;
});


        const handleChallengeInput = (e) => {
            var name = e.target.name;
            var value = e.target.value;
            console.log(value)
            switch (name) {
                case 'title':
                    setTitle(value);
                    break;
                case 'prize':
                    setPrize(value);
                    break;
                case 'institutename':
                    setInstitutename(value);
                    break;
                default:
                    break;
            }
        }
        const [title, setTitle] = useState("");
        const [prize, setPrize] = useState("");
        const [institutename, setInstitutename] = useState("");
        const [institutelogo, setInstitutelogo] = useState({
            preview: ChallengeInstituteImg,
            data: ''
        })
        const handleLogoChange = (e) => {
            const img = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            }
            setInstitutelogo(img)
        }
        const handleUploadData = async (e) => {
            let formData = new FormData()
            formData.append('questions', e.target.files[0])


            const qfile =  e.target.files[0]
            var filebaseencoded= await toBase64(qfile)

            formData.append('questionsencoded', filebaseencoded)

            setbulkImport(1)
            const response = await fetch(Url + "uploadcontestquestion", {
                    method: "POST",
                    body: formData
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {


                           var challengeimportdata =data.data
setTimeout(function(){

                          Object.keys(challengeimportdata).forEach(function(key) {



    var qid = "question"+challengeimportdata[key].s_no
    var aid = "answer"+challengeimportdata[key].s_no
    var opid2 = "option2"+challengeimportdata[key].s_no
    var opid3 = "option3"+challengeimportdata[key].s_no
    var opid4 = "option4"+challengeimportdata[key].s_no


document.getElementById(qid).value=challengeimportdata[key].question

document.getElementById(aid).value=challengeimportdata[key].rightanswer
document.getElementById(opid2).value=challengeimportdata[key].option2
document.getElementById(opid3).value=challengeimportdata[key].option3
document.getElementById(opid4).value=challengeimportdata[key].option4


 
});

},500)

                        setchallengesCount(data.data);
                    } else {}
                })
        }




         /***Standard only***/
        const [standardsList, setstandardsList] = useState([]);
        const [standard, setStandard] = useState("");
        const [Standardoptions, setstandardOptions] = useState([{
                value: '5',
                label: '5th'
            },
            {
                value: '6',
                label: '6th'
            },
            {
                value: '7',
                label: '7th'
            },
            {
                value: '8',
                label: '8th'
            },
            {
                value: '9',
                label: '9th'
            },
            {
                value: '10',
                label: '10th'
            },
            {
                value: '11',
                label: '11th'
            },
            {
                value: '12',
                label: '12th'
            }
        ]);
        const [selectedStandardOption, setselectedStandardOption] = useState({
            value: '',
            label: 'Select Standard*'
        })
        const emptyselectedStandardOption = () => {
            setselectedStandardOption({
                value: ' ',
                label: ' '
            })
        }
        const handleChangeStandard = (value) => {
            document.getElementsByClassName('select-standard-input')[0].classList.remove('notify-input-missing');
            setStandard(value.value);
            setselectedStandardOption(value)
        }
        /***Standard only***/



        /**Subject***/
        const [subject, setSubject] = useState("");

        const [Subjectoptions, setsubjectsOptions] = useState();
        const [selectedSubjectOption, setselectedSubjectOption] = useState({ value: '', label: 'Select Subject*' }  )

           const  emptyselectedSubjectOption =() => {
          setselectedSubjectOption({ value: ' ', label: ' ' })
        }
        const handleChangeSubject = (value) => {
        document.getElementsByClassName('select-subject-input')[0].classList.remove('notify-input-missing');
        setSubject(value.value);
        setselectedSubjectOption(value)
        }

 

        /**Subject***/


          const GetSubjects = async (value, reset = '') => {

              (async function() { 

             setsubjectsOptions  ( await GetSubjectsCommon())

             })()


          }





        useEffect(() => {
            GetChallengeCounts()
            GetSubjects();
        }, []);



                const setQuestionFieldValue = (e) => {


                    e.target.value = e.target.value

                }

        


              return (

<div>
   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar showBottomNavbar={BottomNavbarstatus} />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
         <div className="page-content">
            <div className="container-mobile">
               <div>




                {(showSetupContestQuestionSection == 1 ) ? 
                  <div>
                     <div className="page-container-section" >
                        <h1 className="page-container-title cur-poi"  > 
                           <span className="goto-section  " > <a onClick={showSetupcontest}     >  <img src={angleLeft} /> </a>  </span> Add Questions
                        </h1>
                     </div>


                       <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-25">

                      <a   download   href={frontUrl +"assets/challenge/questions-template.csv"}  className=  "me-1 mb-1 csv-btn mt-50  "    > Download Sheet Here </a> 
                      </div>



                     <p> <span className="highlight-clr">Note : You have to download excel file in which you
will fill your question and answers, please read labels
before you enter your options. These answers will be
shuffled before shared with kids.</span> </p>

 
 <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-25">
    <div className="button-wrap">
        <label className="csv-btn cur-poi" for="upload">Upload Sheet Here</label>
        <input id="upload" type="file" onChange={handleUploadData} name="samplefile" className="display_none"  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"      />
          
 
      </div>
 </div>


    <p className="text-white text-center"> (or) </p>



{ (bulkImport == 1) ? 


    <form class="form form-horizontal">
           {challengesCount.map((challengeSingle) => (
<div>
   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   row ">
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   display_flex "> 


      <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2  padding-10"> 
      <span className="add-q-s-no">{challengeSingle.s_no} </span>
      </div>
      <div className="col-md-10 col-lg-10 col-sm-10 col-xs-10  "> 
            <input  id={'question'+challengeSingle.s_no}     type="text" name="question[]"  className="new-challenge-input MustEnterField mb-10" placeholder="Enter Questions" />

      </div>





      </div>
      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12    ">  <input id={'answer'+challengeSingle.s_no}   type="text"  name="option1[]" className="new-challenge-input MustEnterField mb-10" placeholder="Right Answer / Option1" /></div>
      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12    ">     <input id={'option2'+challengeSingle.s_no}       type="text" name="option2[]" className="new-challenge-input MustEnterField mb-10" placeholder="Option2" /></div>
      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12    ">    <input id={'option3'+challengeSingle.s_no}      type="text" name="option3[]" className="new-challenge-input MustEnterField mb-10" placeholder="Option3" /></div>
      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12    ">       <input id={'option4'+challengeSingle.s_no}     type="text" name="option4[]" className="new-challenge-input MustEnterField mb-10" placeholder="Option4" /></div>
   </div>
   <hr/>
</div>

  ))}



 

   </form> : '' }





{ (bulkImport == 0) ? 


    <form class="form form-horizontal">
           {challengesCount.map((challengeSingle) => (
<div>
   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   row ">
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   display_flex "> 


      <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2  padding-10"> 
      <span className="add-q-s-no">{challengeSingle.s_no} </span>
      </div>
      <div className="col-md-10 col-lg-10 col-sm-10 col-xs-10  "> 
            <input    type="text" name="question[]"   className="new-challenge-input MustEnterField mb-10" placeholder="Enter Questions" />

      </div>





      </div>
      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12    ">  <input   type="text"   name="option1[]" className="new-challenge-input MustEnterField mb-10" placeholder="Right Answer / Option1" /></div>
      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12    ">     <input     type="text" name="option2[]" className="new-challenge-input MustEnterField mb-10" placeholder="Option2" /></div>
      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12    ">    <input       type="text" name="option3[]" className="new-challenge-input MustEnterField mb-10" placeholder="Option3" /></div>
      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12    ">       <input   type="text" name="option4[]" className="new-challenge-input MustEnterField mb-10" placeholder="Option4" /></div>
   </div>
   <hr/>
</div>

  ))}



 

   </form> : '' }






<p className="error-msg">{questionerrormsg}</p> 
            { (showsubmitButton == 1) ?  
                      <button onClick={payContest}   className=  "me-1 mb-1 contest-sec-btn mt-50 bg-yellow-amigo"    >Next</button> : ''}


                      </div>
                     : '' }





                  {(showSetupContestSection == 1 ) ? 
                  <div>
                     <div className="page-container-section" >
                        <h1 className="page-container-title cur-poi"  > 
                           <span className="goto-section  " > <a    onClick={showAgreement} >  <img src={angleLeft} /> </a>  </span> Set up my contest
                        </h1>
                     </div>
                     <p className="text-white text-left">This is the first step to setup your contest</p>
                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row">
                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 ">

                        


                         <div className="button-wrap">
                         <label for="uploadins"><img src={institutelogo.preview}   className="img-responsive challenge-ins-image"  /> </label>
                         
                         
        <input id="uploadins" type="file" className="display_none"  accept="image/png, image/gif, image/jpeg" name="thumbnail"    onChange={handleLogoChange}  />
          
 
      </div>



                        </div>
                        <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8">
                        <div className="challenge-ins-upload">
                           <p className="text-white text-left">Add your photo or institute logo</p>
                           </div>
                        </div>
                     </div>

                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                        <input  id="title"  autoComplete="off"   type="text"  onChange={(e) => handleChallengeInput(e)} value={title}  name="title"   className="form-control stream-input mb-25 MustEnterField"   placeholder="Contest Title"    />
                     </div>


                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                        <input id="institutename"    autoComplete="off"   type="text"  onChange={(e) => handleChallengeInput(e)} value={institutename}  name="institutename"   className="form-control stream-input mb-25 MustEnterField"   placeholder="Institute or Your  Name"    />
                     </div>


  <Select className="form-control   MustEnterField select-register-input select-subject-input" 
                     name={"subject"}
                     value={selectedSubjectOption}
                     onChange={handleChangeSubject}
                     options={Subjectoptions}
                     />


                      <Select className="form-control   MustEnterField select-register-input select-standard-input" 
                name={"standard"}
                value={selectedStandardOption}
                onChange={handleChangeStandard}
                options={Standardoptions}
                 />  

                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                        <input id="prize"    autoComplete="off"   type="number"  onChange={(e) => handleChallengeInput(e)}  name="prize"  value={prize}  className="form-control stream-input mb-25 MustEnterField"   placeholder="Prize"    />
                     </div>
                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                        <div className="date-time-stream ">
                           <div className="date-picker-section cur-poi" onClick={(value)=>ShowDatepickerSection(1)}  > 
                              <span ><img src={calendarIcon}  />   <span> Select Date</span> </span> 
                           </div>
                           <div className="date-picker-section"  > 
                              <span className="display_flex" ><img src={clockIcon}  /> <span>      <input value={time} name="time" onChange={(e) => setTimevalue(e.target.value)}  className="time-picker-input MustEnterField" type="time" /> 
                              </span>  </span> 
                           </div>
                        </div>
                        <div className="date-time-picker-section"  >
                           {(showdatePickeroption == 1) ?
                           <div className="date-picker-section"  >
                              <DatePicker dateFormat="MMMM d, yyyy" onChange={(date) =>
                              setDatevalue(date)}    minDate={currentDate}
                              inline selected={date}    />
                           </div>
                           :''}
                           <div className="time-picker-section"  > 
                           </div>
                        </div>
                        {(displaydate != '' ) ?  
                        <p className="live-stream-select-date">  Selected Date & Time :  <span>  {displaydate} </span><span>  {displaytime} </span></p>
                        : '' }

                        <p className="error-msg">{timeerrormsg}</p>
                     </div>
                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
                        <p className="highlight-clr text-left">
                           Note : We recommend at least 50 questions contest 
                           for 30 min 
                        </p>
                        <p className="highlight-clr text-left">
                           There may be number of contest running in 
                           our platform. Make sure your rewards are attractive
                           also try to reach kids in your area and ask them 
                           participate. Make sure your contest time is feasible 
                           for kids.
                        </p>
                        <p className="highlight-clr text-left">
                           We don’t guarantee you number of participate, this
                           is only platform to publish your institute. Also prize 
                           amount is not refundable.
                        </p>
                     </div>
                     <button onClick={showSetupcontestQuestion}   className=  "me-1 mb-1 contest-sec-btn mt-50 bg-yellow-amigo"    >Next</button>
                  </div>
                  :''}



                  {(showAgreementSection == 1 ) ? 
                  <div>
                     <div className="page-container-section" >
                        <h1 className="page-container-title cur-poi"  > 
                           <span className="goto-section  " >  <Link  to={-1}>  <img src={angleLeft} /> </Link>  </span> Agreement
                        </h1>
                     </div>
                     <div className="contest-agreement">
                        <p><span className="contest-highlight">My motive is to publish my institute. I will only 
                           post those challenges which will help kids in their 
                           academic . I will follow all rules and regulations of </span> <b className="stream-author"> 
                           Fststeps Pvt. Ltd.</b>
                        </p>
                        <h5>Rules and Regulations</h5>
                        <ol>
                           <li>Only Academic content</li>
                           <li>No comments on Religion, Caste or Nationality</li>
                           <li>No abusive language </li>
                           <li>I have right to advertise my classes or tuitions</li>
                           <li>I understand  FSTsteps Pvt. Ltd., doesn’t guarantee 
                              number of participant. And they are not liable for any 
                              losses
                           </li>
                           <li>I will deposit Challenge prize amount during 
                              registration and it is not refundable
                           </li>
                           <li>Fstetps Pvt. Ltd., is only providing platform and they
                              are not responsible for my actions.
                           </li>
                        </ol>
                        <h5>Payments</h5>
                        <ol>
                           <li>Fststeps Pvt. Ltd.,  will collect the fee from students </li>
                           <li>
                              I understand Fststeps Pvt. Ltd., is the aggregator and
                              they will charge 100% commission fee. 
                           </li>
                           <li>
                              Per student will be charged Rs. {challengeFees}/- for per challenge
                           </li>
                        </ol>
                        <h5>Price and Rewards</h5>
                        <ol>
                           <li>I ensure me or my institute will award Winners and 
                              amount will Rs. 1000/ or more cash.
                           </li>
                           <li>
                              Winner will be selected based less time taken.
                           </li>
                        </ol>
                          </div>

                        <button onClick={showSetupcontest}   className=  "me-1 mb-1 contest-sec-btn mt-50 bg-yellow-amigo"    >I agree</button>


                   
                  </div>
                  :''}














               </div>
            </div>
         </div>
      </div>
   </div>
</div>

	)













 }
export default Addchallenge;