import React, { useState ,useEffect} from "react";
     import {
    Link,
    useNavigate
} from "react-router-dom";
 import Sidebar from "../includes/Sidebar";

    import { angleLeft} from '../ImagesIcons.js';


import {checkUsertypeRedirectIfStudent,GetThumbnailImage,Akusername,frontUrl,SiteLogo,AkuserId,Url  ,SetNotificationAfterName,RemoveNotificationAfterName ,Notifyalert} from '../Frontapi.js';






 
const Newpost = () => {
  const navigate = useNavigate();

const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

const [topic, setTopic] = useState("");
const [caption, setCaption] = useState("");
const [youtubeurllink, setYoutubeurllink] = useState("");
 const [thumbnail, setThumbnail] = useState({ preview: '', data: '' })

const [contactname, setContactName] = useState("");
const [contactnumber, setContactNumber] = useState("");

const [submitButton, showSubmitbutton] = useState(1);

 const [adDuration, setadduration] = useState("")
 const [adFees, setadfees] = useState("")


 const [Conditionsagreed, setConditionsagreed] = useState("")




const Getuserdetails =async(e) =>{

 
   var postdata = {
        user_name: Akusername , checkuserid:AkuserId, mobileneed:1
    };

     const response = await fetch(Url + "get-show-profile", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success && data.data !== null) {
                    var userinfo = data.data;
                    var lastname='';
                    if(typeof userinfo.lastname != 'undefined'){
                          lastname=userinfo.lastname
                    }

                setContactName(userinfo.name + ' '+lastname)
                    setContactNumber(userinfo.mobile)
    

                }  
            })




}



const Createnewpost =async(e) =>{

  e.preventDefault();

       

   

  /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove();
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         el.classList.add('mb-10');
                        if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                         el.classList.remove('mb-10');
                        error=1;

                       

                        //SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });

            //var termschecked =  document.getElementsByClassName('ad-terms-conditions')[0].checked     ;
          document.getElementsByClassName('ad-condition-check-sec')[0].classList.remove('notify-input-missing');

         //   if(termschecked == false){
                // error=1;
                //   document.getElementsByClassName('ad-condition-check-sec')[0].classList.add('notify-input-missing');

         //   }


            if(Conditionsagreed == false){
                 error=1;
                   document.getElementsByClassName('ad-condition-check-sec')[0].classList.add('notify-input-missing');

            }

              if(error === 1){
                    return false
            }
             /***Check Required***/

                 

          if(thumbnail.data == ''){

                 // document.getElementsByClassName('button-upload')[0].classList.add('notify-input-missing');

               // return false
             
          }

if(checkvalidYoutubeUrl()  == false){
      return false
}

showSubmitbutton(0)

    let formData = new FormData()
      formData.append('thumbnail', thumbnail.data)
      formData.append('userid',  AkuserId)
      formData.append('topic',  topic)
      formData.append('caption',  caption)
      formData.append('youtubeurllink',  youtubeurllink)
      formData.append('adv', 1)
      formData.append('contactname',  contactname)
      formData.append('contactnumber',  contactnumber)



         const response = await fetch(Url+"create-new-ad", {
        method: "POST",
        body:formData 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            
 
               window.location.href=data.redirect 
              

          } else{
                        showSubmitbutton(1)

               Notifyalert('error', data.message)
          }

           
       })
 
  

}


 const checkvalidYoutubeUrl=(e)=>{

   RemoveNotificationAfterName();

   if( youtubeurllink != '' &&  (youtubeurllink.includes('https://youtu.be') === false &&  youtubeurllink.includes('https://www.youtube.com/shorts/') === false )  ){
    showSubmitbutton(0)
    SetNotificationAfterName('youtubeurllink',   'Invalid Url' )
    return false
    } 

 }


   const handleUserInput = (e) => {
    showSubmitbutton(1)
 //   checkvalidYoutubeUrl()
    var name = e.target.name;
    var value = e.target.value;
    switch (name) {
        case 'topic':
            setTopic(value);
             break;
        case 'youtubeurllink':
            setYoutubeurllink(value);
            setAutothumbnail(value)
             
            checkvalidYoutubeUrl()

             break;
        case 'caption':
            setCaption(value);
             break;
        case 'contactname':
            setContactName(value);
             break;
        case 'contactnumber':
            setContactNumber(value);
             break;    


                    
         
      }
    }



 const handleThumbnailChange = (e) => {
      RemoveNotificationAfterName();

     const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
     setThumbnail(img)
  }
  
  const setAutothumbnail = (value) => {


  (async function() { 

   var imgresult= await GetThumbnailImage(value)
 
 const img = {
      preview:imgresult,
      data: '',
    }
     setThumbnail(img)


})()
 



 

  }



 const Getsettings = async (e) => {
  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-front-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
            setadduration(settingsdata.adduration );
    setadfees(settingsdata.adfees );


            
         
          } else{
              //  ;
          }

           
       })



  } catch (error) {
      
  }

 } 


 const handleAgreeUserInput = async (e) => {


 var name = e.target.name;
            var checkedvalue = e.target.attributes.getNamedItem('data-attr-checked').value;
            if (checkedvalue == 1) {
                var value = 0;
                e.target.setAttribute('data-attr-checked', 0);
                e.target.classList.remove('checked');
            } else {
                var value = 1;
                e.target.setAttribute('data-attr-checked', 1);
                e.target.classList.add('checked');
            }

     setConditionsagreed(value);

 }



 const checkUsertypeRedirect = async (value) => {


         await checkUsertypeRedirectIfStudent()

           
        };


   useEffect(() => {

     checkUsertypeRedirect();
 

    Getuserdetails();
    Getsettings();
  
 

  
    },[] );

  return (

      <div>
       

     
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
          <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 "> 

               
<Sidebar showBottomNavbar={BottomNavbarstatus} />

           </div>
            <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 full-section"> 


              <div className="page-content">

                <div className="container-mb"> 
                <form id="NewPostForm" >
                 <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > <span className="goto-section" >   <Link to={-1}><img src={angleLeft} /></Link>
 </span> New Advertisement</h1>
                </div>


      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   post-input-sec"> 

        <input onChange={(e) => handleUserInput(e)} type="text" value={contactname} name="contactname" className="new-post-input MustEnterField mb-10" placeholder="Name" />
         </div>

           <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   post-input-sec"> 

        <input onChange={(e) => handleUserInput(e)} type="text" value={contactnumber} name="contactnumber" className="new-post-input MustEnterField mb-10" placeholder="Mobile Number" />
         </div>



                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   post-input-sec"> 

                    <input onChange={(e) => handleUserInput(e)} type="text" name="youtubeurllink" className="new-post-input MustEnterField mb-10" placeholder="Paste YouTube URL link of your content" />
                     </div>



 <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12     post-input-sec post-input-file-sec"> 

                     <div className="button-wrap">
        <label className="button-upload" for="upload">Attach Thumbnail</label>
        <input id="upload" type="file"  accept="image/png, image/gif, image/jpeg" name="thumbnail"   onChange={handleThumbnailChange} />
          
                     {(thumbnail.preview != '') ? <img alt="thumbnail" className="thumb-img-prev img-responsive w-250px"  src={thumbnail.preview} />:''}

      </div>

 </div>


                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   post-input-sec"> 
                    <input onChange={(e) => handleUserInput(e)} type="text" name="topic" className="new-post-input MustEnterField mb-10" placeholder="Topic" />
                    </div>

                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12     post-input-sec"> 
                    <textarea onChange={(e) => handleUserInput(e)} name="caption" placeholder="Write Caption" rows="10" className="new-post-textarea MustEnterField mb-10"></textarea>
                    </div>

                   
                      

                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12  ad-condition    post-input-sec "> 
                    <h2>Condition</h2>
                    <p>Attached file is for commercial advertisement use 
                    only, I understand this is small kid forum and no 
                    adultery content is accepted. In case any adultery 
                    Content application owner will not publish my 
                    advertisement. We will be capturing your coordinates (pincode) to publish the advertisement.</p>

                    <p>I am willing to pay Rs. {adFees}/-  for {adDuration} days advertising
                    my product in my geo location of 5 km.</p>
                    </div>

                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12  row  ad-condition ad-condition-check-sec  post-input-sec "> 

                     <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 col-3 preference">


                    {/*   <input type="checkbox" className="ad-terms-conditions" /> */}

                      <label className="switch">
                      <input type="checkbox"  data-attr-checked={Conditionsagreed ==1 ? '1':''} className={Conditionsagreed ==1 ? 'checked':''}   onClick={(e)=>handleAgreeUserInput(e)}     />
                      <span className="slider round"></span>
                    </label>


                     </div>
                     <div  className="col-md-10 col-lg-10 col-sm-10 col-xs-10 col-9"><p>I understand and accept above conditions</p></div>

                     </div>

                   


                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12  post-input-sec"> 
 {(submitButton == 1) ? 
                      <button type="submit" className=" me-1 mb-1 new-post-main-btn bg-yellow-amigo "  onClick={Createnewpost}   >Confirm</button>
                   
                      
                       : 

 <button type="submit" className=" me-1 mb-1 new-post-main-btn disabled-btn"   >Confirm</button>



                   }


                    </div>
                      
</form>

               </div>
               </div>



           </div>
              

     </div>
    
           

      </div>
 
 
  );
};
export default Newpost;