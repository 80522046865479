import React, { useState ,useEffect} from "react";
     import {
    Link,
    useNavigate
} from "react-router-dom";
 import Sidebar from "../includes/Sidebar";

  import {angleLeft  } from '../ImagesIcons.js';



import {Akusertype,postDetailurl,frontUrl,SiteLogo,AkuserId,Url  , SiteLogoDark,SetNotificationAfterName,RemoveNotificationAfterName ,Notifyalert,UrlSplitter} from '../Frontapi.js';



 


 
const Newpost = () => {
  const navigate = useNavigate();

const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

const [orderlist, setOrderlist] = useState([]);
   
  const [nPages, setnPages] = useState(1);
    const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));
    const [totalCount,settotalCount] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(5);

const Getorders =async(value) =>{

 
        
var postdata = {'currentpage':value,'perpage':recordsPerPage, userid : AkuserId }

         const response = await fetch(Url+"get-orders", {
        method: "POST",
         headers: {
                    "Content-Type": "application/json",
                },
 body: JSON.stringify({
                    postdata
                }),         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            var orderslist = data.data
 
            
            setOrderlist(orderslist)

            
             settotalCount(data.count)
                if(data.count > 1){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }


          } else{
               
          }

           
       })
 
  

}

 const goToNextPage = () => {
             if(currentPage !== nPages) {
                setCurrentPage(currentPage + 1) 
                Getorders()
             }
    }
    const goToPrevPage = () => {
        if(currentPage !== nPages){
            setCurrentPage(currentPage - 1)
             Getorders()

        }  
   }


      const goToPage = (value) => {
           setCurrentPage(value)
          
             Getorders(value)  
                 

        }  

      





  useEffect(() => {

  Getorders(1);
 
 
   

    }, [ ]);


 
  return (

      <div>
       

     
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
          <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 "> 

               
<Sidebar showBottomNavbar={BottomNavbarstatus} />

           </div>
            <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 full-section"> 


              <div className="page-content">

                <div className="container-mb"> 
                <form id="NewPostForm" >
                 <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   >  <span className="goto-section  mob-view-back display_none" > <Link  to={-1}>  <img src={angleLeft} /> </Link>   </span> Orders</h1>
                </div>


                {(Akusertype == 1) ?
                 <div className=" col-md-12 col-lg-12 col-12 row mb-25" >
                  <div className=" padding-0  fl-right" >
                  <Link to='/newad' className="create-ad-btn" >Create Ad</Link>    
                     </div>
               </div> : ''}

               { (orderlist.length > 0) ?

                <div className="order-section-wrapper">

                {orderlist.map((ordersingle) => (
                <div className="order-section-single">


                 <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row"> 
                                   <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3"> 
                                   <Link to={postDetailurl+ordersingle.postid } >  <div className="order-section-img">   <img src={ordersingle.thumbnail } /></div>
                                   </Link>
                                   </div>

                                     <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-8"> 
                                       <div className="order-section-info">
                                       <p className="order-post-topic"> <Link to={postDetailurl+ordersingle.postid } > {ordersingle.topic } </Link>  </p>
 <p> {ordersingle.createddatetime } </p>

 <p> Post Status : { ordersingle.adstatus   } </p> 

   { (ordersingle.refundstatus != '-')?  <p className="txt-cap"> Refund Status : {ordersingle.refundstatus.toLowerCase()}  </p>  : ''   } 


  </div>
                                     </div>

                                      <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 col-4"> 
                                       <div className="order-section-status">
                                          <span className={'order-status-'+ordersingle.paymentstatus.toLowerCase()  } > {ordersingle.paymentstatus }  </span>      
   <span className="order-post-price"><span className="order-currency-symbol">₹</span>{ordersingle.price } </span>

  { (ordersingle.invoicelink != '')? <span className="order-download-invoice"> <a  target="_blank"  href={ordersingle.invoicelink}  ><i className="fa fa-download"></i> Invoice </a> </span>   :''}


 </div>
                                      </div>

                 </div>

           
                </div>

                ))}

{  pageNumbers.length > 1 ? 

       <nav>
   <ul className='pagination pagination-primary justify-content-center'>
      <li className="page-item prev-paging-single">
         <a className="page-link" 
            onClick={goToPrevPage} 
            href='#'>
         Previous
         </a>
      </li>
       {pageNumbers.map(pgNumber => (
      <li key={pgNumber} 
      className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
      <a onClick={(e) => goToPage(pgNumber)  }  
      className='page-link' 
      href='#'>
      {pgNumber}
      </a>
      </li>
      ))}
      <li className="page-item paging-next-single">
         <a className="page-link" 
            onClick={goToNextPage}
            href='#'>
         Next
         </a>
      </li>
   </ul>
</nav>
: ''

}


                </div> 





                : <p className="no-records">No Orders Found </p> }
              

                  

  
</form>

               </div>
               </div>



           </div>
              

     </div>
    
           

      </div>
 
 
  );
};
export default Newpost;